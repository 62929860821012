@import '~@/themes/sm/custom.scss';
.img_default {
  // 因为基本都是远程图片，默认 cover
  object-fit: cover;
  // 正常需要 auto 但是我们外出已经控制了大小 在Nextjs版本的时候，所以可以直接 100%
  //height: 100%;
  //width: 100%;
}
.animation_img {
  opacity: 0;
  transform: scale(0.4);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}
.transform_image {
  opacity: 1 !important;
  transform: scale(1) !important;
}
