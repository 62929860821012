@import '~@/themes/sm/custom.scss';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'PingFang SC';
  font-style: normal;
  height: 100%;
  width: 100%;
  font-size: 12px;
  margin: 0 auto;
  position: relative;
  // 默认白色-后面有白色主题需要切换
  color: #fff;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  #root {
    height: 100%;
    overflow: auto;
  }
}

* {
  padding: 0;
  margin: 0;
  outline: 0 none;
  box-sizing: border-box;
  word-break: break-all;
  font-family: 'PingFang SC';
  font-style: normal;
  -webkit-tap-highlight-color: transparent;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ka-wrapper {
  height: 100%;
}

.ka-content {
  height: 100%;
}

input {
  user-select: auto;
}

ol,
ul {
  list-style: none;
  list-style-type: none;
  margin-bottom: 0px !important;
}

a {
  color: inherit;
  text-decoration: none;
  &:focus {
    text-decoration: none;
  }
}

*::-webkit-scrollbar {
  display: none;
  width: 0;
  opacity: 0;
  -webkit-overflow-scrolling: touch;
}

*::-webkit-scrollbar:horizontal {
  display: none;
  height: 0;
  opacity: 0;
  -webkit-overflow-scrolling: touch;
}

:root {
  // 如果要做刘海屏兼容可以用到 --safe-top --safe-bottom
  --safe-top: 0px;
  --safe-bottom: 0px;
  --swiper-theme-color: #ff4452 !important;
  --nextui-danger: 338.48deg 101% 71.57% !important;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-top: constant(safe-area-inset-top);
    --safe-bottom: max(calc(constant(safe-area-inset-bottom) - 15px), 0px);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-top: env(safe-area-inset-top);
    --safe-bottom: max(calc(env(safe-area-inset-bottom) - 15px), 0px);
  }
}

// @media (prefers-color-scheme: dark) {
//   :root {
//     --max-width: 1100px;
//   --primary-color: #000000;
//   }
// }
// @media (prefers-color-scheme: dark) {
//   html {
//     color-scheme: dark;
//   }
// }

@media (prefers-color-scheme: dark) {
  .xgplayer .xgplayer-progress .xgplayer-progress-btn:before {
    @include theme {
      background-color: t(primary-color) !important;
    }
  }
}
