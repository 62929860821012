@import '~@/themes/sm/custom.scss';
.container {
  position: relative;
  z-index: 10;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  background-size: 100% auto;
  width: 100%;
  height: 100%;
  .loading {
    .loading_img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .loading_text {
    padding-top: 10px;
    font-size: 14px;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
