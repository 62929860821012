@import '~@/themes/sm/custom.scss';
.main {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: calc(100% - 54px);
  box-sizing: border-box;
  &.hide_footer {
    min-height: 100%;
  }
  padding-top: var(--safe-top);
  padding-bottom: var(--safe-bottom);
  @include theme {
    background-color: t(bg-color-primary);
  }
  &.linear_bg::before {
    content: '';
    height: 48.267vw;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    @include theme {
      background: t(bg-view-gradient);
    }
  }
}
.globalDownload {
  width: 90px;
  // height: 40px;
  position: fixed;
  bottom: 50px;
  cursor: pointer;
  left: 50%;
  margin-left: -45px;
  z-index: 2000;
  img {
    width: 100%;
    // height: 100%;
  }
}
.advPop {
  width: 71px;
  height: 71px;
  position: fixed;
  bottom: 158px;
  right: 10px;
  z-index: 2000;
  // border-radius: 6px;
  // overflow: hidden;
  img {
    width: 100%;
    border-radius: 9px;
  }
  .close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -22px;
    right: 4px;
    z-index: 99999;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
