@import '~@/themes/sm/custom.scss';
.notFound {
  text-align: center;
  font-size: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 44px);
  position: relative;
  top: -60px;
  .image {
    width: 335px;
    max-width: 90%;
    height: 325px;
    background-image: url('~@/assets/images/img_404.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .desc {
    font-size: 16px;
    color: #666666;
    text-align: center;
  }

  .backLable {
    font-size: 18px;
    text-align: center;
    margin-top: 13px;
    > span {
      cursor: pointer;
      @include theme {
        color: rgb(from t(primary-color) r g b / 90%);
      }
      &:hover {
        @include theme {
          color: t(primary-color);
        }
      }
      > a {
        @include theme {
          color: rgb(from t(primary-color) r g b / 90%);
        }
        &:hover {
          @include theme {
            color: t(primary-color);
          }
        }
      }
    }
  }
}
