@import '~@/themes/sm/custom.scss';
.container {
  :global {
    .adm-mask-content {
      .adm-toast-wrap {
        .adm-toast-main {
          padding: 10px 26px !important;
          border-radius: 50px !important;
          font-size: 14px;
          box-shadow: 2px 3px 5px 1px rgba(0, 0, 0, 0.2);
          letter-spacing: 1px;
          position: fixed;
          z-index: 1000;
          max-width: 80%;
          white-space: nowrap;
          transform: translateX(-50%);
          @include theme {
            background: t(btn-bg-color-primary);
            color: t(btn-text-color-primary);
          }
        }
      }
    }
  }
  &.top {
    :global {
      .adm-mask-content {
        .adm-toast-wrap {
          .adm-toast-main {
            top: 7% !important;
          }
        }
      }
    }
  }
}
