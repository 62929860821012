@use 'sass:map';
// 定义公共scss及函数

// 公共主题样式
@mixin theme {
  @each$theme-name, $theme-map in $themes {
    $theme-map: $theme-map !global;
    [data-theme='#{$theme-name}'] & {
      @content;
    }
  }
}
// 根据key获取主题
@function t($key) {
  @return map-get($theme-map, $key);
}

// 文字溢出省略
.textNowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// PWA 状态栏
@mixin container-with-pwa($bgColor) {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: var(--safe-top);
  padding-bottom: var(--safe-bottom);
  // background-color: #301010;
  background: $bgColor;
}

// 下拉刷新动画
.box-spinner {
  height: 60px;
  transition: height .5s linear;
}

.slide-height-enter {
  height: 0;
}
.slide-height-enter-active {
  height: 60px;
  opacity: 1;
  transition: height .1s linear;
}
.slide-height-exit {
  height: 60px;
  opacity: 0;
}
.slide-height-exit-active {
  height: 0;
  transition: height .5s linear;
}

// 动画
@keyframes img-zoom-in {
    0% {
      width:0px;
      height:0px;
    }
    100% {
      width:100%;
      height:100%;
    }
}
.img-zoom-in{
		animation-name: img-zoom-in;
		animation-duration: 0.2s;
		animation-timing-function: linear;
		animation-delay: 0s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: forwards;

		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin:auto;
}
