@import '~@/themes/sm/custom.scss';
.container {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: auto;
  position: fixed;
  bottom: 0;
  z-index: 49;
  backdrop-filter: blur(1px);
  padding-bottom: var(--safe-bottom);
  @include theme {
    background: t(bg-color-primary);
  }

  > ul {
    display: flex;
    justify-content: space-between;
    padding: 9px;
    flex: 1 1;
    li {
      flex: 1 1;
      width: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include theme {
        color: t(bg-text-color-tertiary);
      }
      font-size: 10px;
      -webkit-text-size-adjust: none;

      > svg {
        margin-bottom: 2px;
        @include theme {
          color: t(bg-color-primary);
          fill: t(bg-text-color-tertiary);
        }
      }
      &.active {
        @include theme {
          color: t(primary-color);
        }
        > svg {
          @include theme {
            fill: t(secondary-color);
            color: t(secondary-color);
          }
        }
      }
      &.disable {
        @include theme {
          color: rgb(from t(bg-text-color-tertiary) r g b / 50%);
        }
        > svg {
          @include theme {
            fill: rgb(from t(bg-text-color-tertiary) r g b / 50%);
          }
        }
      }
    }
  }
}
